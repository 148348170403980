.resume {
    @extend %anchor-scrool;
    position: relative;

    &:after {
        @include full-viewport($container-max-widths);
        background: $gray-light;
    }

    > div {
        margin-top: $spacer * 1.5;
        padding-bottom: $spacer * 2;
        border-bottom: 1px solid $gray-100;

        &:last-child {
            padding-bottom: 0;
            border-bottom: 0;

        }
    }

    ul {
        margin-bottom: 0;
        font-size: $font-size-sm;
    }

    .company {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media-breakpoint-up(lg) {
            @include make-col(4);
        }
    }

    .job {
        @include media-breakpoint-up(lg) {
            @include make-col(8);
        }
    }

    h3 {
        @include font-size($h5-font-size);
    }

    .company-name {
        @extend %heading;
        @include font-size($h6-font-size);

        div {
            font-size: $font-size-base;
            font-style: italic;
        }

        &:before {
            content:'@';
            color: $gray-600;
            font-weight: 400;
        }
    }

    .duration {
        @extend %heading;
        @include font-size($h6-font-size);
        flex-shrink: 0;
    }
}
