.hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - $nabvar-height);
    color: #fff;

    &:after {
        content: '';
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        width: 100%;
        height: 100vh;
        background: linear-gradient(to left bottom, $blue-darker 0%, $blue-darker 50%, $blue-dark 100%);
        z-index: -1;
    }

    .content {
        padding-top: 2rem;
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: $spacer * 2;

        .logo {
            max-width: 136px;
            margin-bottom: $spacer * 2;
        }
    }

    > img {
        @extend %hero-visual;
    }
}
