.profil {
    @extend %anchor-scrool;

    .card {
        display: flex;
        align-items: center;
        margin-top: $spacer * 2;

        img {
            border-radius: 100%;
            margin-right: $spacer * 2;
        }
    }

    .skills {
        margin-top: $spacer * 3;
    }

    .skill {
        text-align: center;
        margin-bottom: $spacer * 2;

        > p {
            font-size: $font-size-sm;
            line-height: $line-height-sm;
            text-align: justify;
        }

        img {
            margin-bottom: $spacer;
        }

        h3 {

        }

        .list {
            p {
                text-align: initial;
            }
        }

        ul {
            padding-left: 0;
        }

        li {
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;

            div {
                flex-shrink: 0;
            }
        }
    }
}
