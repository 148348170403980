@import "bootstrap";

@mixin full-viewport($container-max-widths) {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    @each $breakpoint, $container-max-width in $container-max-widths {
        @include media-breakpoint-up($breakpoint) {
            left: calc((100vw - #{$container-max-width}) / -2);
        }
    }
}

$footer-img-pos: $spacer * 4;

@import "navbar";
@import "hero";
@import "profil";
@import "resume";
@import "projects";
@import "footer";

#root {
}

%anchor-scrool {
    scroll-margin-top: $nabvar-height-sticky;
}

%hero-visual {
    max-width: $spacer * 30;
    margin-left: -10%;
}

%heading {
    div {
        font-weight: $font-weight-light;
        font-family: $font-family-base;
    }
}

@mixin container-spacing($grid-gutter-width) {
    padding: $grid-gutter-width $grid-gutter-width;

    @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width * 2;
        padding-bottom: $grid-gutter-width * 2;
    }
}

footer {
    @include container-spacing($grid-gutter-width * 1.5);
}

main {
    padding-top: $nabvar-height;

    .container {
        @include container-spacing($grid-gutter-width);

        >h1 {
            margin-bottom: $spacer * 3;
        }

        >h2 {
            margin-bottom: $spacer * 2.5;
        }
    }
}
