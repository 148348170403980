$logo-height: 7rem;
$logo-height-sticky: 3rem;

$nabvar-padding-y: $grid-gutter-width;
$nabvar-padding-y-sticky: $nabvar-padding-y * .5;

$nabvar-height: $logo-height + $nabvar-padding-y * 2;
$nabvar-height-sticky: $logo-height-sticky + $nabvar-padding-y-sticky * 2;

nav {
    position: absolute;
    color: #fff;
    display: flex;
    padding: $nabvar-padding-y;
    justify-content: space-between;
    align-items: center;
    z-index: $zindex-fixed;
    width: 100%;

    &.sticky {
        position: fixed;
        animation: moveDown 0.5s ease-in-out;
        padding-top: $nabvar-padding-y-sticky;
        padding-bottom: $nabvar-padding-y-sticky;

        svg {
            height: $logo-height-sticky;
            margin-right: 1rem;

            path {
                &[fill="#{$primary}"] {
                    animation: rotate 0.5s ease-in-out 0.4s;
                    transform-origin: center;
                }
            }
        }

        ul {
            flex-direction: row;

            a {
                font-size: .9rem;
            }
        }
    }

    svg {
        height: $logo-height;
        width: auto;
    }

    ul {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    li {

        a {
            font-family: $headings-font-family;
            color: #fff;
            line-height: $btn-line-height;
            padding: $btn-padding-y $btn-padding-x;
            border: 1px solid transparent;
            text-decoration: none;
            color: inherit;
            border-radius: $border-radius-lg;
            @extend .btn;

            &:hover,
            &:focus,
            &.active {
                @include button-variant($primary, $primary);
                color: #fff;
                background-color: $primary;
            }
        }
    }

    &:after {
        content: '';
        position: absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to left bottom, $blue-darker 0%, $blue-darker 50%, $blue-dark 100%);
        z-index: -1;
    }
}

@keyframes moveDown {
    from {
        transform: translateY(-5rem);
    }
    to {
        transform: translateY(0rem);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0rem);
    }
}
