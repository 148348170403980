.projects {
    .projects-list {
        $projects-list-gutters: $spacer * 1.5;

        display: flex;
        flex-wrap: wrap;
        margin-left: - $projects-list-gutters;
        margin-right: - $projects-list-gutters;
        margin-bottom: - $projects-list-gutters;

        .project {
            display: flex;
            @include make-col(6);
            flex-direction: column-reverse;
            margin-bottom: $projects-list-gutters;
            padding-right: $projects-list-gutters;
            padding-left: $projects-list-gutters;
            align-self: start;
            text-decoration: none;

            // @include media-breakpoint-up(lg) {
            //     @include make-col(6);
            // }

            &:first-child {
                width: 100%;
            }

            img {
                width: 100%;
                max-width: $spacer * 46;
                border-radius: $border-radius;
                box-shadow: $box-shadow;
                margin-bottom: $projects-list-gutters * .5;
            }
        }

        .title {
            h3 {
                @include font-size($h4-font-size);
            }

            div {
                font-size: $font-size-base;
                color: $gray-600;
            }
        }
    }
}

.project {
    .tagline {
        font-size: $font-size-base;
        color: $gray-600;
    }

    img {
        margin: auto;
        text-align: center;
    }
}
