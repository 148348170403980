@use "sass:math";

footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: $blue-medium;

    @include media-breakpoint-up(lg) {
        justify-content: space-between;
    }

    svg {
        path {
            fill: #fff;
        }
    }

    .social {
        display: flex;
        align-items: center;
        margin-bottom: $spacer;

        a {
            width: $spacer * 2;
            height: $spacer * 2;
            margin-left: math.div($spacer, 1.5);
            @include border-radius($border-radius, 0);
            background: $blue-medium;
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }
    }

    &:after {
        @include full-viewport($container-max-widths);
        background: linear-gradient(to left top, $blue-darker 0%, $blue-dark 100%);
    }
}
